import React, { useCallback } from 'react'
import { Input } from '../../core/components/forms'
import useLoginPage from './hooks/useLoginPage'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useAsyncAction from '../../core/utilities/useAsyncAction'
import ButtonLoading from '../../core/components/Button/button-loading'
import { preventDuplicateCall } from '../../core/utilities/concurrent'
import { AxiosError } from 'axios'

const catchAPIError =
  <T extends unknown>(handle400ErrorFunction: (error: T) => void) =>
  (e: any) => {
    if (e.name === 'AxiosError') {
      const axiosError: AxiosError<{ messages: T }> = e
      if (axiosError.response?.data.messages) {
        handle400ErrorFunction(axiosError.response?.data.messages)
      }
    }
  }
const showOneError = (
  errors: { [key: string]: string[] },
  setError: (
    path: any,
    error: { message: string },
    option: { shouldFocus: boolean }
  ) => void
) => {
  Object.keys(errors).forEach((key) => {
    if (errors[key]) {
      setError(key, { message: errors[key][0] }, { shouldFocus: true })
    }
  })
}
const loginValidateSchema = yup
  .object({
    email: yup
      .string()
      .email('must be valid email')
      .required('This field is required'),
    password: yup
      .string()
      .min(6, 'Please input at least 6')
      .required('This field is required')
  })
  .required()
type LoginFormInputType = {
  email: string
  password: string
}
const LoginPage = () => {
  const loginSevice = useLoginPage()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginFormInputType>({
    resolver: yupResolver(loginValidateSchema)
  })

  const [loginByEmailAndPassword, loggingByEmailAndPassword] = useAsyncAction(
    useCallback(
      (value: LoginFormInputType) =>
        loginSevice.loginByEmailAndPassword(value.email, value.password).catch(
          catchAPIError<{ email: string[]; password: string[] }>((error) => {
            showOneError(error, setError)
          })
        ),
      [loginSevice, setError]
    )
  )

  return (
    <form
      className="container"
      onSubmit={handleSubmit(
        useCallback(
          () => preventDuplicateCall(loginByEmailAndPassword),
          [loginByEmailAndPassword]
        )
      )}>
      <div>
        <div>
          <Input
            feedbackText={errors.email?.message}
            state={errors.email && 'error'}
            id="email"
            {...register('email')}
            placeholder="email"
          />
        </div>
        <div>
          <Input
            feedbackText={errors.password?.message}
            state={errors.password && 'error'}
            id="password"
            {...register('password')}
            placeholder="password"
          />
        </div>
      </div>
      <div>
        <div className="text-white">
          <ButtonLoading
            loading={loggingByEmailAndPassword}
            disabled={loggingByEmailAndPassword}>
            button
          </ButtonLoading>
        </div>
      </div>
    </form>
  )
}
export default LoginPage
