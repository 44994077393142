export function takeLast<T extends [], R>(
  func: (...args: T) => R,
  wait: number
): (...args: T) => R | void {
  let timeout: NodeJS.Timeout | null
  return function executeFunction(...args: T) {
    const later = function () {
      timeout = null
      //   clearTimeout(timeout)
      func(...args)
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(later, wait)
  }
}
export function preventDuplicateCall<T extends any[], R>(
  func: (...args: T) => R
): (...args: T) => R | void {
  return takeLast(func, 500)
}
/**
 *
 * @param func it's a stricky invention
 * @returns
 */
export function takeFirstResolveAll<ARG extends any[], T>(
  func: (...args: ARG) => Promise<T>
): (...args: ARG) => Promise<T> {
  let state: 'created' | 'excuting' | 'finished' = 'created'
  const listResolveCallBack: {
    resolve: (args: T) => void
    reject: () => void
  }[] = []
  return function executedFunction(...args: ARG) {
    if (state !== 'excuting') {
      state = 'excuting'
      return func(...args)
        .then((rs) => {
          while (listResolveCallBack.length > 0) {
            listResolveCallBack.pop()?.resolve(rs)
          }
          return rs
        })
        .finally(() => {
          state = 'finished'
        })
    }
    return new Promise<T>((resolve, reject) => {
      listResolveCallBack.push({ resolve, reject })
    })
  }
}
