import { useRouter } from 'next/router'
import { useCallback } from 'react'
import useLoginUser from '../../authentication/hooks/useLoginUser'

/**
 * Contain login page logic
 * @returns
 */
const useLoginPage = () => {
  const login = useLoginUser()
  const router = useRouter()
  return {
    loginByEmailAndPassword: useCallback(
      (email: string, pasword: string) => {
        return login(email, pasword).then((rs) => {
          // router.push(``)
          return rs
        })
      },
      [login]
    )
  }
}
export default useLoginPage
