import { useMemo, useState } from 'react'

export default function useAsyncAction<T extends any[], R>(
  action: (...args: T) => Promise<R>,
  deps: any[] = []
): [(...args: T) => Promise<R>, boolean] {
  const [loading, setLoading] = useState(false)

  return [
    useMemo(
      () =>
        async (...args: T) => {
          setLoading(true)
          try {
            return await action(...args)
          } finally {
            setLoading(false)
          }
        },

      [action]
    ),
    loading
  ]
}
