import { useEffect, useMemo } from 'react'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import { takeFirstResolveAll } from '../../../core/utilities/concurrent'
import { fetchCurrentUser } from './useLoginUser'
export type CurrentUserType = {
  fullName: string
  photoUrl: string
}

const useCurrentUserStore = create<{
  user?: CurrentUserType
  fetchingMe: boolean
  fetchSuccess: boolean
  getUser: () => Promise<CurrentUserType>
  setUserAfterLogin: (user: CurrentUserType) => void
}>()(
  persist(
    (set, get) => {
      return {
        fetchingMe: false,
        fetchSuccess: false,
        getUser: takeFirstResolveAll(() => {
          const userStore = get()
          if (userStore.user) {
            return Promise.resolve(userStore.user)
          }
          set({
            ...userStore,
            fetchingMe: true
          })
          return fetchCurrentUser().then((user) => {
            set({
              ...userStore,
              user: user,
              fetchingMe: false,
              fetchSuccess: true
            })
            return user
          })
        }),
        setUserAfterLogin: (user) => set({ ...get(), user, fetchSuccess: true })
      }
    },
    {
      name: 'current-user', // unique name
      getStorage: () => sessionStorage // (optional) by default, 'localStorage' is used
    }
  )
)
export const useCurrentUser = () => {
  const userStore = useCurrentUserStore()
  useEffect(() => {
    userStore.getUser()
  }, [userStore])
  return useMemo(
    () => ({
      fetching: userStore.fetchingMe,
      user: userStore.user
    }),
    [userStore.user, userStore.fetchingMe]
  )
}
export const useIsUserLogin = () => {
  return () => false
}
export const useSetCurrentUserAfterLogin = () => {
  const userStore = useCurrentUserStore()
  return userStore.setUserAfterLogin
}
